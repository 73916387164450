<template>
  <div class="second">
    <Crumbs :breadList="crumbs" />
    <div class="main">
      <div class="l_box">
        <LeftMenu
          :title="parentItem.name"
          :menus="menus"
          :selectedIndex="selectedIndex"
          @monitorMenus="monitorMenus"
        />
      </div>
      <div class="r_box">
        <!-- <div class="content" v-html="content"></div> -->
        <NewsList
          v-show="isShowList"
          :list="list"
          :pageNo="pageNo"
          :pageSize="pageSize"
          :total="total"
          :current="current"
          @paging="onPaging"
        />
        <Details v-show="!isShowList" :item="item"></Details>
      </div>
    </div>
  </div>
</template>
<script>
import Crumbs from "@/components/crumbs/index";
import LeftMenu from "@/components/leftMenu/index";
import NewsList from "@/components/newsList/index";
import Details from "@/components/details/index";
import { getTreeItem } from "@/utils/index";
export default {
  components: {
    Crumbs,
    LeftMenu,
    NewsList,
    Details,
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      leftList: [],
      crumbs: [],
      menus: [],
      selectedIndex: 0,
      list: [],
      pageNo: 1,
      pageSize: 0,
      total: 0,
      current: 1,
      parentItem: null, // 父级对象
      childrenItem: null, // 子级对象
      isShowList: true,
      item: null, // 详情
      type: 0, // 1详情 还是以 0列表展示
      articleId: ''
    };
  },
  methods: {
    init() {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      this.parentItem = getTreeItem(myMenus, this.$route.query.pid, "id");
      this.menus = this.parentItem.children; // 菜单
      let sid = this.$route.query.sid;
      if (sid) {
        this.childrenItem = getTreeItem(myMenus, this.$route.query.sid, "id");
        this.menus.forEach((item, index) => {
          if (item.id == this.childrenItem.id) {
            this.selectedIndex = index;
          }
        });
      } else {
        this.selectedIndex = 0;
      }
      if (this.parentItem.children.length > 0) {
        this.type = this.menus[this.selectedIndex].type;
        this.getList(this.menus[this.selectedIndex].id);
      } else {
        this.list = [];
        this.item = null;
        this.total = 0;
        this.pageSize = 0;
        this.current = 1;
      }
      this.updateCrumbs(); // 构建面包
    },
    async getList(id) {
      console.log({id: id,
        pageNo: this.pageNo,})
      let res = await this.$api.second.articleList({
        id: id,
        pageNo: this.pageNo,
      });
      // type 0列表 1文章 2联系我们 3通知公告
      if (res.status == 200) {
        console.log("res --- >", res)
        if (this.type == 0 || this.type == 3) {
          this.isShowList = true;
          res.data.records.forEach((item) => {
            item.createTime = item.createTime.substr(0, 10);
          });
          this.list = res.data.records;
          this.total = res.data.total;
          this.pageSize = res.data.size;
          this.current = res.data.current;
        } else {
          this.item = res.data.records.length > 0 ? res.data.records[0] : null;
          this.articleId = res.data.records.length > 0 ? res.data.records[0].id : "";
          if(this.articleId) {
             this.getArticleDetails()
          }
          this.isShowList = false;
        }
      }
    },
    // 获取文章详情
    async getArticleDetails() {
      if (this.articleId) {
        let res = await this.$api.second.articleDetail(this.articleId);
        if (res.status == 200) {
          // Object.assign(this.item, res.data);
          this.item = res.data
        }
      } 
    },
    monitorMenus(index, item) {
      this.pageNo = 1;
      this.$router.push({
        path: "/second",
        query: { pid: this.parentItem.id, sid: item.id },
      });
    },
    onPaging(value) {
      this.pageNo = value;
      this.getList(this.childrenItem.id);
    },
    updateCrumbs() {
      if (this.crumbs.length == 0) {
        this.crumbs.push({
          name: "首页",
          link: "/",
        });
        if (this.parentItem.children.length > 0) {
          this.crumbs.push({
            name: this.parentItem.name,
            link:
              "/second?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.push({
            name: this.childrenItem.name,
            link: "",
          });
        }
      } else {
        if (this.parentItem.children.length > 0) {
          this.crumbs.splice(1, 1, {
            name: this.parentItem.name,
            link:
              "/second?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.splice(2, 1, {
            name: this.childrenItem.name,
            link: "",
          });
        } else {
          this.crumbs.splice(1, 2);
          this.crumbs.push({
            name: this.parentItem.name,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
