<template>
  <div class="details">
    <template v-if="item">
      <div class="title">
        {{ item.showTitle == 1 ? item ? item.title : "" : ""}}
      </div>
      <div class="date" v-if="item.showDate == 1">
        时间：{{ item ? item.createTime : "" }}
      </div>
      <div class="html" v-html="item ? item.content : ''"></div>
      <div class="btm">
        <slot></slot>
      </div>
      <div class="read">阅读量:{{ item.browseCount }}</div>
    </template>
    <div v-else class="not-data">暂无数据</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentData: null
    };
  },
  mounted() {
    // var father = document.getElementsByClassName("html")[0];
    // console.log("father 获取class为html---", father);
    // var imgs = father.getElementsByTagName("img");
    // console.log("获取到到图片集合", imgs);
    // for (let i = 0; i < imgs.length; i++) {
    //   console.log("为" + imgs[i] + "添加click事件");
    //   imgs[i].addEventListener("click", function () {
    //     console.log("点击图片的地址： ", imgs[i].getAttribute("src"));
    //   });
    // }
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    isFind: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  // watch: {
  //   item: {
  //     handler(newValue, oldValue) {
  //       console.log("触发 -- ", newValue, oldValue)
  //       this.getArticleDetails();
  //     },
  //     deep: true,
  //     // immediate: true,
  //   },
  // },
  methods: {
    // // 获取文章详情
    // async getArticleDetails() {
    //   console.log("this.isFind:",this.isFind)
    //   if (this.item.id) {
    //     let res = await this.$api.second.articleDetail(this.item.id);
    //     if(res.status == 200) {
    //       this.contentData = res.data
    //     } else {
    //       this.contentData = this.item
    //     }
    //     console.log("contentData --- ", this.contentData)
    //     // console.log("调用获取详情 id:", this.item.id);
    //     // console.log("调用获取详情 结果:", res);
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.not-data {
  min-height: 472px;
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}
.details {
  position: relative;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    line-height: 25px;
    padding-top: 35px;
    padding-bottom: 25px;
  }
  .date {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    margin-bottom: 35px;
  }
  .html {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    line-height: 32px;
  }
  .btm {
    width: 100%;
    float: left;
    margin-top: 130px;
    .link {
      display: block;
      .not-a {
        color: #666666;
        &:hover {
          color: #666666;
        }
      }
      a {
        font-size: 14px;
        color: #222222;
        line-height: 32px;
        &:hover {
          color: #c01515;
        }
      }
    }
  }
  .read {
    position: absolute;
    top: -25px;
    right: 10px;
    color: #666666;
    font-size: 14px;
  }
}
</style>
